import React, { useState } from 'react';
import RewyLayout from '../../layouts/rewy/index'
import StarIcon from '../../../content/assets/images/star-icon.png';
import HowItsWork from '../../../content/assets/images/howitworks.png';
import {Link} from 'gatsby';
import { Flex, Box, Button, Heading, Input } from 'theme-ui';

const HowItWork = () => {

  const [zipValue, setZipValue] = useState('');

  const zipChange = (event) => {
    setZipValue(event.target.value)
  };

  const zipHandler = () => {
    if (!zipValue.length) {
      localStorage.removeItem('zip');
    }

    if (zipValue.length >= 3 && zipValue.length <= 5) {
      localStorage.setItem('zip', zipValue);
    }
  }

  return (
    <RewyLayout>
      <section className="how-its-work-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={StarIcon} alt="image"/>
                                Are you legally compliant?
                            </span>
                <h4>Find Tailored Solutions For Your Business</h4>
                <p>Save money and time with instant, actionable guidance</p>
                <div className="inner-box">
                  <div className="single-item">
                    <div className="count-box">
                      1
                    </div>
                    <h3>Discover Regulatory Requirements</h3>
                    <p>Taxes, licenses, permits, certificates</p>
                  </div>
                  <div className="single-item">
                    <div className="count-box">
                      2
                    </div>
                    <h3>Manage Compliance Needs</h3>
                    <p>Get control on your mandatory legal requirements</p>
                  </div>
                  <div className="single-item">
                    <div className="count-box">
                      3
                    </div>
                    <h3>Find Funding and Insurance Offers</h3>
                    <p>Discover curated loans, grants and insurance plans</p>
                  </div>
                  <div className="subscribe-content">
                                   <form className="newsletter-form">
                                    <input type="text" className="input-newsletter" name="zip" label="Zip" onChange={zipChange} placeholder="ZipCode" required />
                                    <Link as={Link} to="/compliance" variant="primary" aria-label="Download Report" onClick={zipHandler} className="default-btn">
                                     <i className="flaticon-tick"></i> 
                                      Get Started <span></span>
                                    </Link>
                                   </form>
                                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="how-its-work-image">
                <img src={HowItsWork} alt="image"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </RewyLayout>
  );
};

export default HowItWork;